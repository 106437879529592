<template>
  <section id="comparison">
        <div class="toggle">
            <button @click="packageComparisonIsOpen = !packageComparisonIsOpen" :class="[packageComparisonIsOpen ? 'open' : '']">
                <span>View Package Comparison</span>
                <ArrowButton invert :rotate="packageComparisonIsOpen"/>
            </button>
        </div>       
        <transition name="fade" mode="out-in" >
            <div class="package-comparison-holder" v-show="packageComparisonIsOpen">                    
                <div class="package-comparison">                    
                    <PackageTypes @typeSelected="typeSelected"/>
                    <transition name="fade" mode="out-in" appear>
                        <PackageTabs @tabSelected="tabSelected" :selectedType="selectedType"/>
                    </transition>
                    <div class="info">
                        <table>
                            <tr>
                                <th class='benefit'>Benefit</th>
                                <th class='leader'>Leaders Circle</th>
                                <th class='premier'>Premier Circle</th>
                            </tr>
                            <tr v-for="(benefit, id) in $store.state.packageInfoTypes[selectedType].tabs[selectedTab].benefits" :key="id">
                                <td>
                                    <p class="title" v-html="benefit.title"/>
                                    <p class="description" v-html="benefit.genericDescription !== undefined ? benefit.genericDescription : benefit.description"></p>
                                </td>
                                <td v-html="benefit.leader"/>
                                <td v-html="benefit.premier"/>
                            </tr>
                        </table>
                    </div>
                </div>                            
            </div>        
        </transition>
    </section> 
</template>

<script>
import ArrowButton from '@/patterns/ArrowButton';
import PackageTypes from '@/patterns/PackageTypes';
import PackageTabs from '@/patterns/PackageTabs';
export default {
    name:'PackageComparison',
    components:{ ArrowButton, PackageTypes, PackageTabs},
    data: () => ({
        packageComparisonIsOpen:false,        
        selectedType:0,
        selectedTab:0,
    }),    
    methods : {
        typeSelected(id) {
            this.selectedType = id; 
            this.selectedTab = 0;
        },
        tabSelected(id) {
            this.selectedTab = id; 
        }
    }
}
</script>

<style lang="scss">       
    .package-comparison td span {
        display: block;        
        font-size: 0.85em;
        font-weight: 300;
        text-transform: none;
        margin-top:6px;
    }
</style>
<style lang="scss" scoped>       
    table {
        color:#000;
        text-align: left;
        width: 1024px;
        th {
            color: #FFFFFF;
            font-family: "Gotham Narrow";
            font-size: 1em;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 1;
            width:340px;
            height:50px;            
            padding:1em;
            box-sizing: border-box;
            &.benefit {
                background: linear-gradient(135deg, #202020 0%, #161616 100%);
            }
            &.leader {
                background: linear-gradient(90deg, #C2AB64 0%, #96844B 100%);
            }
            &.premier {
                background: linear-gradient(90deg, #929292 0%, #535353 100%);
            }
        }
        .title, .description {
            color: #161616;
            font-family: "Gotham Narrow";
            font-size: 0.85em;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 1;
            font-weight: 300;
            text-transform: none;
            margin:0;
        }
        .title {
            font-size: 1em;
            font-weight: bold;
            text-transform: uppercase;
        }        
        .description {
            margin-top:6px;
            line-height: 1.5;
        }
        tr {
            td {
                background-color: #EDEDED;
                padding:1em;
                font-family: "Gotham Narrow";
                font-size: 1em;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 1;
                text-transform: uppercase;
            }
            &:nth-child(2n) td {
                background-color: #E1E1E1;
            }
        }
    }
    .package-comparison-holder {
        position: relative;
        &:before {
            content: "";
            background: linear-gradient(135deg, #202020 0%, #161616 100%);
            position: absolute;
            bottom:0;
            left:0;
            width:100%;
            height:calc(100% - 35px);
        }
    }
    .info {
        overflow: auto;
        max-width: 1024px;
        width: 100%;
    }
    .package-comparison {
        max-width: 1044px;
        width: 100%;
        margin:0 auto;
        padding:10px;
        background:#fff;     
        margin-top:-25px;   
        position: relative;     
        box-sizing: border-box;   
    }        
    .toggle {
        margin:68px 0;
        position: relative;
        button {
            position: relative;
            background:#fff;
            box-shadow: 0 10px 20px 0 rgba(0,0,0,0.15);
            width:100%;
            max-width: 1024px;
            border-radius: 3px;
            padding:25px;
            text-align: left;
            color: #161616;
            font-family: "Gotham Narrow";
            font-size: 1.3em;
            font-weight: bold;          
            cursor: pointer;   
            span, .arrow {
                vertical-align: middle;
            }
            .arrow {
                float:right;
            }                   
        }
        &:before {
            content:" ";
            height:1px;
            width: 100%;
            position: absolute;
            left:0;
            top:50%;
            background-color: #E1E1E1;
        }
    }
    h3 {
        max-width: 1024px;
        margin:0 auto;
        margin-bottom:25px;
        padding:0 1em;
    }      
    @media (min-width:560px) {       
    }
</style>