<template>
    <section>
        <Gallery title="Your Opportunity" number="02.svg" subtitle="Premier Circle" :images="[
        {
            title:'SOFI Stadium',
            file:'package_premier.jpg'
        }        
        ]"/>   
        <section id="premier-circle">
            <h3>Package Details: <span class="grey">Premier Circle</span></h3>
        </section>
        <section class="package-types">
            <PackageTypes @typeSelected="typeSelected" link='benefits'/>            
        </section>
        <section class="package-tabs" id='benefits'>
            <transition name="fade" mode="out-in" appear>
                <PackageTabs @tabSelected="tabSelected" :selectedType="selectedType" :selectedTab="selectedTab"/>
            </transition>        
        </section>
        <section class="package-benefits">
            <Benefit v-for="(benefit, id) in $store.state.packageInfoTypes[selectedType].tabs[selectedTab].benefits" :key="id" :benefit="benefit" type="premierNum"/>
        </section>
        <PackageComparison />
        <Linear forcedPath='/your-opportunity/above-and-beyond' forcedTitle='Above & Beyond'/>
    </section>
</template>

<script>

import Gallery from '@/patterns/Gallery';
import PackageComparison from '@/components/PackageComparison';
import PackageTypes from '@/patterns/PackageTypes';
import PackageTabs from '@/patterns/PackageTabs';
import Benefit from '@/patterns/Benefit';
import Linear from '@/patterns/Linear';
export default {
    name:'PremierPackage',
    components:{Gallery, PackageComparison, PackageTypes, PackageTabs, Benefit, Linear},
    data: () => ({
        selectedType:0,
        selectedTab:0,
    }),    
    mounted() {},
    beforeDestroy: function () {},
    methods : {
        typeSelected(id) {
            this.selectedType = id; 
            this.selectedTab = 0;
        },        
        tabSelected(id) {
            this.selectedTab = id; 
        }
    }
};
</script>

<style lang="scss" scoped>
    .package-benefits {
        max-width: 1024px;
        margin:0 auto;
        padding:0 8px;        
    }    
    .package-types {
        background:#fff;
        padding:10px;        
        max-width: 1044px;
        margin:0 auto;
        margin-bottom:16px;
        box-sizing: border-box;
        box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
        .types {
            padding:0;
        }
    }
    .package-tabs {
        max-width: 1024px;
        margin:20px auto;
        margin-bottom:16px;
        box-sizing: border-box;    
        border-bottom:1px solid #D9D9D9;
        padding:0 10px;
        scroll-margin-top: 90px;
    }
    #premier-circle {
        max-width: 1024px;
        margin:0 auto;
    }
    h3 {
        margin-top:95px;
        padding:0 1em;
        .grey {
            color:#7F7F7F;
        }
    }
    @media (min-width:750px) {
        .package-benefits {
            display: flex;
        }
    }
</style>