<template>
    <section>
        <Gallery title="Your Opportunity" subtitle="Contribution" :images="[
        {
            title:'SOFI Stadium',
            file:'contribution.jpg'
        }        
        ]"/>     
        <section class='contribution'>
            <Packages @packageSelected="setSite"/> 
        </section>
        <ContributionCalculator :isLeader="isLeader" :donation="donation" :total="total" :signing="signing" :p0="p0" :p1="p1"/> 
    </section>
</template>

<script>

import Gallery from '@/patterns/Gallery';
import Packages from '@/patterns/Packages';
import ContributionCalculator from '@/patterns/ContributionCalculator';


export default {
    name:'Contribution',
    components:{Gallery, ContributionCalculator, Packages},      
    mounted() {},
    beforeDestroy: function () {},
    methods: {
        setSite(type){
            if(type == 'leader') {
                this.isLeader = true; 
                this.donation = 250000; 
                this.total = 300000; 
                this.signing = 100000; 
                this.p0 = 100000; 
                this.p1 = 100000;
            } else {
                this.isLeader = false; 
                this.donation = 100000; 
                this.total = 125000; 
                this.signing = 42000; 
                this.p0 = 42000; 
                this.p1 = 41000
            }
        }
    },
    data: () => ({
        donation:250000,
        total:300000,
        signing:100000,
        p0:100000,
        p1:100000,
        fee:1,
        isLeader:true,
     }),  
};
</script>

<style lang="scss" scoped>                   
    h3 {
        max-width: 1024px;
        margin:0 auto;
        margin-bottom:25px;
        padding:0 1em;
    }  
    .contribution {        
        h3 {
            margin-top:95px;
        }
    }       
</style>
