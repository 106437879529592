<template>
    <section>
        <Gallery title="Your Opportunity" subtitle="Above<br/>& Beyond" :images="[
        {
            title:'SOFI Stadium',
            file:'above-and-beyond.jpg'
        }        
        ]"/>     
        <div class="" id="above-and-beyond">
            <div>
                <article>
                    <div>
                        <div>
                            <h3 class='uppercase'>Contribute To<br /> A <span class="gold">Greater LA</span></h3>
                            <p>Your contribution directly impacts economic, community and civic growth initiatives throughout <nobr>the city</nobr></p>
                        </div>
                    </div>
                    <img src="@/assets/above-and-beyond/contribute.jpg" alt="">
                </article>
                <article class='override-order'>
                    <div>
                        <div>
                            <h3 class='uppercase'>Your<br /> <span class="gold">Donation</span></h3>
                            <p>Your ChampionLA donation will be made to the Los Angeles Sports & Entertainment Commission, which is a 501(c)3 California Nonprofit Public Benefit Corporation. Donors should consult their tax advisor about the tax deductibility of <nobr>this donation.</nobr></p>
                        </div>
                    </div>
                    <img src="@/assets/above-and-beyond/your-donation.jpg" alt="">
                </article>
                <article>
                    <div>
                        <div>
                            <h3 class='uppercase'><span class="gold">Recognition</span></h3>
                            <p class="bold">In appreciation of your generosity, you will be recognized:</p>
                            <div class='recognition'><span class="bold gold">01</span> <span>In an LA Times full-page ad leading up to the Super Bowl</span></div>
                            <div class='recognition'><span class="bold gold">02</span> <span>On the ChampionLA Partners page on the LASEC website</span></div>
                        </div>
                    </div>
                    <img src="@/assets/above-and-beyond/recognition.jpg" alt="">
                </article>
                <article class='override-order'>
                    <div>
                        <div>
                            <h3 class='uppercase'>Personalized<br /> <span class="gold">Service</span></h3>
                            <p>You will be provided a dedicated ChampionLA concierge to coordinate and ensure use of all <nobr>donor benefits</nobr></p>                           
                        </div>
                    </div>
                    <img src="@/assets/above-and-beyond/personalized-service.jpg" alt="">
                </article>
                <article>
                    <div>
                        <div>
                            <h3 class='uppercase'><span class="gold">Leaders</span> of LA</h3>
                            <p>Each donor will be invited to ChampionLA events to meet and develop relationships with other likeminded LA businesses and <nobr>community leaders</nobr></p>                           
                        </div>
                    </div>
                    <img src="@/assets/above-and-beyond/leaders-of-la.jpg" alt="" style="object-position:top left;">
                </article>
            </div>
        </div>  
        <Linear forcedPath='/your-opportunity/contribution' forcedTitle='Contribution'/>                      
    </section>
</template>

<script>

import Gallery from '@/patterns/Gallery';
import Linear from '@/patterns/Linear';
export default {
    name:'AboveAndBeyond',
    components:{Gallery, Linear},
    data: () => ({ }),    
    mounted() {},
    beforeDestroy: function () {},
    methods: {},
};
</script>

<style lang="scss" scoped>  
    .recognition {
     text-align: left;     
     margin-top:0.8em;
     .bold {
         margin-right: 8px;
         width: 20px;
         display: inline-block;
     }   
    }
    nobr { white-space:nowrap; }  
    article {            
      > div {
          display: flex;
          align-items: center;
          justify-content: center;    
          padding:4em;      
      }
      img {
          object-fit: cover;
          width:100%;
          max-height: 450px;
      }      
    } 
    h3 {
        color: #fff;
        font-family: "Gotham Narrow";
        font-size: 2em;
        font-weight: 900;
        letter-spacing: 2px;
        line-height: 1;
        margin:0;
        margin-bottom: 20px;;
        &:after {
            margin-top: 20px;;
            width:100%;
            max-width:380px;
            height:1px;
            background:#414141;
            display: block;
            content:" ";
        }
    }    
    ul, p {
        text-align: left;
        font-family: "Gotham Narrow";
        font-size: 1em;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 1.5;
        max-width: 380px;
        margin:0;
        &.bold {                        
            font-size: 1.4em;
            font-weight: bold;
        }
    }
    ul {
        margin-top:16px;
        li {
            max-width: 300px;
            &:not(:last-child) {
                margin-bottom:8px;
            }
        }
    }    
    #above-and-beyond {
        padding-top:35px;   
        margin-bottom:0;
        > div {
            margin-top:35px;
            background: linear-gradient(135deg, #202020 0%, #161616 100%);
        }     
        &:before {
            width:100%;
            max-width: 1024px;
            margin:0 auto;
            height:1px;
            background:#D9D9D9;
            display: block;
            content:" ";
        }  
    }
    @media (min-width:925px) {
        article {
            display:flex;  
            justify-content: space-between;
            max-height: 450px;
            > div {
                padding:1em;
            }
            > * {
                width: 50%;
                flex-grow: 0;
                flex-shrink: 0;
                box-sizing: border-box;
            }
            img {
                width: 50%;
            }
        }        
        .override-order {
          img {
              order: -1;
          }
      }
    }
</style>
