var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('Gallery',{attrs:{"title":"Your Opportunity","subtitle":"Packages","images":[
    {
        title:'SOFI Stadium',
        file:'packages.jpg'
    }        
    ]}}),_c('div',{staticClass:"package-buttons"},[_c('div',{staticClass:"package-button",style:({
                    'background-image':'url(' + require('@/assets/gallery/package_leaders.jpg') + ')',
        })},[_c('div',{staticClass:"info"},[_vm._m(0),_c('router-link',{attrs:{"to":"packages/leaders-circle"}},[_vm._v("VIEW PACKAGE DETAILS")])],1),_c('img',{attrs:{"src":require("@/assets/icons/01.svg"),"alt":""}})]),_c('div',{staticClass:"package-button",style:({
                    'background-image':'url(' + require('@/assets/gallery/package_premier.jpg') + ')',
        })},[_c('div',{staticClass:"info"},[_vm._m(1),_c('router-link',{attrs:{"to":"packages/premier-circle"}},[_vm._v("VIEW PACKAGE DETAILS")])],1),_c('img',{attrs:{"src":require("@/assets/icons/02.svg"),"alt":""}})])]),_c('PackageComparison'),_c('Linear',{attrs:{"forcedPath":"/your-opportunity/packages/leaders-circle","forcedTitle":"Leaders Circle"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title"},[_vm._v("Leaders"),_c('br'),_vm._v("Circle")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title"},[_vm._v("Premier"),_c('br'),_vm._v("Circle")])}]

export { render, staticRenderFns }