<template>
    <section>
        <Gallery title="Your Opportunity" subtitle="Packages" :images="[
        {
            title:'SOFI Stadium',
            file:'packages.jpg'
        }        
        ]"/>  
        <div class="package-buttons">
            <div class='package-button' :style="{
                        'background-image':'url(' + require('@/assets/gallery/package_leaders.jpg') + ')',
            }">
                <div class="info">
                    <p class="title">Leaders<br/>Circle</p>
                    <router-link to="packages/leaders-circle">VIEW PACKAGE DETAILS</router-link>
                </div>
                <img src="@/assets/icons/01.svg" alt="">
            </div> 
            <div class='package-button' :style="{
                        'background-image':'url(' + require('@/assets/gallery/package_premier.jpg') + ')',
            }">
                <div class="info">
                    <p class="title">Premier<br/>Circle</p>
                    <router-link to="packages/premier-circle">VIEW PACKAGE DETAILS</router-link>
                </div>
                <img src="@/assets/icons/02.svg" alt="">
            </div> 
        </div>
        <PackageComparison />
        <Linear forcedPath='/your-opportunity/packages/leaders-circle' forcedTitle='Leaders Circle'/>
    </section>
</template>

<script>

import Gallery from '@/patterns/Gallery';
import PackageComparison from '@/components/PackageComparison';
import Linear from '@/patterns/Linear';
export default {
    name:'Packages',
    components:{Gallery, PackageComparison, Linear},
    data: () => ({}),    
    mounted() {},
    beforeDestroy: function () {},
    methods: {},
};
</script>
<style lang="scss" scoped>
.package-buttons {
    padding:25px;
}
.package-button {    
    background-size: cover;
    background-position: center;
    width:100%;
    height:50vh;
    max-height:360px;
    max-width: 1200px;
    margin:0 auto;
    position: relative;
    overflow: hidden;
    padding:40px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    &:first-child {
        margin-bottom: 10px;
    }
    &:before {
        content:" ";
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height: 100%;
    }
    img {
        position:absolute;
        right:0;
        bottom:-30px;
    }
    .info {
        text-align: left;
        width: 100%;
        position: relative;
        z-index: 1;
        .title {            
            font-family: "Gotham Narrow";
            font-size: 2em;
            font-weight: 900;
            letter-spacing: 4px;
            line-height: 1;
            text-transform: uppercase;
            border-bottom: 1px solid #fff;
            padding-bottom: 35px;
        }
        a {
            border-radius: 3px;
            background-color: #C2AB64;
            border: 1px solid #C2AB64;
            padding:16px 30px;
            color: #FFFFFF;
            font-family: "Gotham Narrow";
            font-size: 0.8em;
            font-weight: bold;
            letter-spacing: 2px;
            display: inline-block;
            transition:all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);    
            &:hover{
                color:#161616;
                background-color: #fff;
                border-color: #C2AB64;
            }
        }
    }
}
</style>
    

